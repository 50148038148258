import errMap from './error-map'

/*
* 错误处理类：
* 位于axios返回后，页面收到响应之前，
* 将错误信息统一格式后返回页面,与data同级。
*
* */
export default class Errors {

    originError: any;

    constructor(err: any) {
        this.originError = err;
    }

    buildErrorResponse() {
        let code: number = 0;
        let message: string = '未知错误';
        let res: any = this.originError.response;
        // console.log(res);
        // console.log(res.data);
        if (res?.data?.error_code >= 10000) {
            // 有具体错误代码的业务层错误
            code = res.data.error_code
            message = res.data.message;
        } else if (res?.status === 422) {
            // 用户提交的参数错误，一个或多个。
            code = res.status;
            if (res.data?.errors) {
                message = '';
                for (let i in res.data.errors) {
                    message += `[${res.data.errors[i][0]}]`;
                }
            }
        } else if (res?.status >= 400 && res?.status <= 500) {
            // 框架层或者nginx层的非致命错误（4XX）
            code = res.status;
            if (errMap[code]) {
                message = errMap[code].msg;
            }
        } else if (res?.status > 500 && res?.status < 600) {
            // 框架层或nginx层的服务器错误
            code = res.status;
            message = '服务器错误，请稍候重试';
        }

        /*将错误对象合并进response*/
        Object.assign(res, {
            error: {
                code: code,
                message: message
            }
        })

        // console.log(this.originError)

        return res;

    }
}

