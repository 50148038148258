
const logicError:any = {};

const httpError:any = {
    400: {msg: '无效的请求', },
    401: {msg: '登录过期，请退出后重新进入。', },
    403: {msg: '无权限访问', },
    404: {msg: '请求数据不存在', },
    405: {msg: '请求方法不存在', },
    422: {msg: '请求参数不正确', },
    429: {msg: '操作太频繁，请稍后重试', },
    500: {msg: '服务器错误，请稍后重试', }
};

export default Object.assign(logicError, httpError)
