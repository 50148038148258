import {errTips} from '@/util/error/error_tips';

const storeName = 'bigScreen'

// initial state
const state = {
    /** 实时数据*/
    realTimeData: {},

    /** 设备数据*/
    deviceHoldData: [],
    deviceAlarmData: {},
    deviceUsageData: [],

    /** 医院数据*/
    registerData: {},
    saleCategoryData: [],
    saleData: {},
    hospitalOtherData: [],

    /** 用户数据*/
    visits24HData: {},
    activeUser7DData: {},
    ageData: {},
    genderData: {}
}

// getter
const getters = {}

//actions
const actions = {
    getRealTimeData({commit, getters}: any, payload: any) {
        const vm = payload.vm;
        return vm.$http.get(`/api/v1/monitor/today`).then((res: any) => {
            commit('setState', {
                key: `realTimeData`,
                value: res.data
            });

            return res;
        }).catch((err: any) => {
            // return errTips(vm, err).then(()=>{
            //     return Promise.reject(err);
            // });
        })
    },
    getDeviceData({commit, getters}: any, payload: any) {
        const vm = payload.vm;
        return vm.$http.get(`/api/v1/monitor/device`).then((res: any) => {

            commit('setState', {
                key: `deviceHoldData`,
                value: res.data?.deviceHold
            });
            commit('setState', {
                key: `deviceAlarmData`,
                value: res.data?.waring
            });
            commit('setState', {
                key: `deviceUsageData`,
                value: res.data?.deviceUse
            });

            return res;
        }).catch((err: any) => {
            // return errTips(vm, err).then(()=>{
            //     return Promise.reject(err);
            // });
        })
    },
    getHospitalData({commit, getters}: any, payload: any) {
        const vm = payload.vm;
        return vm.$http.get(`/api/v1/monitor/hospital`).then((res: any) => {

            commit('setState', {
                key: `registerData`,
                value: res.data?.registerData
            });
            commit('setState', {
                key: `saleCategoryData`,
                value: res.data?.saleCategoryData
            });
            commit('setState', {
                key: `saleData`,
                value: res.data?.saleData
            });
            commit('setState', {
                key: `hospitalOtherData`,
                value: res.data?.otherData
            });

            return res;
        }).catch((err: any) => {
            // return errTips(vm, err).then(()=>{
            //     return Promise.reject(err);
            // });
        })
    },
    getUserData({commit, getters}: any, payload: any) {
        const vm = payload.vm;
        return vm.$http.get(`/api/v1/monitor/user`).then((res: any) => {

            commit('setState', {
                key: `visits24HData`,
                value: res.data.visits24HData
            });
            commit('setState', {
                key: `activeUser7DData`,
                value: res.data.activeUser7DData
            });
            commit('setState', {
                key: `ageData`,
                value: res.data.ageData
            });
            commit('setState', {
                key: `genderData`,
                value: res.data.genderData
            });

            return res;
        }).catch((err: any) => {
            // return errTips(vm, err).then(()=>{
            //     return Promise.reject(err);
            // });
        })
    },

}

//mutations
const mutations = {
    setState(state: any, payload: any) {
        state[payload.key] = payload.value
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}