import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './registerServiceWorker'
import './assets/scss/app.scss'

/* config */
import config from './store/config'
Vue.prototype.$cfg = config;

/* dayjs*/
import day from 'dayjs';
Vue.prototype.$day = day

/* echarts */
import ECharts from 'vue-echarts';
import 'echarts'

Vue.component('v-chart', ECharts)

/* roll digit*/
import rollDigit from './components/global/roll-digit.vue';
Vue.component('roll-digit', rollDigit);

/* axios */
import axios from 'axios';
import VueAxios from 'vue-axios'

const instance = axios.create({
    baseURL: config.baseUrl,
    timeout: 30000,
    headers: {
        'clientType': 'web',
        'Access-Control-Allow-Origin' : '*',
        'Access-Control-Allow-Credentials' : 'true',
        'Access-Control-Allow-Methods' : '*',
        'Access-Control-Allow-Headers' : '*'
    }
});

import ErrorClass from '@/util/error';
instance.interceptors.response.use((res: any) => {
    return res
}, (err: any) => {
    // console.log(err.response);
    const errorObj = new ErrorClass(err);
    Object.assign(err,{
        response: errorObj.buildErrorResponse()
    })
    return Promise.reject(err);

});
Vue.use(VueAxios, instance);

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
