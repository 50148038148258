// config
function getBaseUrl() {
    // console.log(process.env)
    let envBaseUrl = process.env.VUE_APP_API_BASE_URL;
    if(!envBaseUrl) {
        return process.env.NODE_ENV === 'development'?'http://':'https://';
    }else {
        return envBaseUrl;
    }
}

function getMqttOption() {
    let mqttUrl = process.env.VUE_APP_mqtt_broker_URL;
    let username = process.env.VUE_APP_mqtt_username
    let password = process.env.VUE_APP_mqtt_password

    let mqttOption:any = {
        url: mqttUrl
    }

    if(username && password) {
        mqttOption.username = username;
        mqttOption.password = password;
    }

    return mqttOption

}


export default {
    baseUrl: getBaseUrl(),
    mqttOption: getMqttOption()
}