import Vue from 'vue'
import Vuex from 'vuex'

import bigScreen from './modules/big_screen'

Vue.use(Vuex)

const modules = {
    bigScreen
}

export default new Vuex.Store({
    modules,
    strict: process.env.NODE_ENV !== 'production'
});
